import moment from "moment";
import {
    surgeries_insertText,
    surgeries_fullPageImage,
    surgeries_getProcedureTitle,
    surgeries_getProcedureDescription,
    surgeries_tick,
    surgeries_surgeonSign,
    convertPDFToImages,
    surgeries_formatDate,
    surgeries_blankPage,
} from "../utils";
import {
    surgeries_adsSurgeryConsent_page,
    surgeries_calConsentMedicalSurgicalTreatment_page,
    surgeries_calPrivacyConsent_page,
    surgeries_wgSurgeryConsent_page
} from "../img/surgeryConsent";

export async function surgeries_surgeryConsentForm(doc, surgery) {
    switch (surgery.clinic.name) {
        case "Adelaide Day Surgery":
            doc.content.push([
                ...surgeries_adsSurgeryConsent(surgery),
            ]);
            return;
        case "Calvary North Adelaide":
            doc.content.push([
                ...surgeries_calConsentMedicalSurgicalTreatment(surgery),
            ]);
            return;
        case "Windsor Gardens Day Surgery":
            doc.content.push([
                ...surgeries_wgSurgeryConsent(surgery),
            ]);
            return;
        case "Port Pirie Regional Health Service":
        case "Port Augusta Hospital":
            const content = await surgeries_ruralSurgeryConsent(surgery);
            doc.content.push([
                ...content,
            ]);
            return;
        default: return;
    }
}

function surgeries_insertDateHeader(dateString) {
    return surgeries_insertText(dateString, 200, 26);
}

function surgeries_adsSurgeryConsent(data) {
    var today = new moment();
    return [{stack: [
        surgeries_fullPageImage(surgeries_adsSurgeryConsent_page(0)),
        surgeries_insertDateHeader(surgeries_formatDate(data.surgery.date, 1)),
        surgeries_insertText(`${data.patient.salutation} ${data.patient.given_names} ${data.patient.surname}`, 125, 170),
        surgeries_insertText(data.clinician.name, 330, 190),
        surgeries_insertText(surgeries_getProcedureTitle(data), 60, 223),
        surgeries_insertText(surgeries_getProcedureDescription(data), 60, 270),
        surgeries_tick(64, 334, 8, 8),
        surgeries_surgeonSign(data.clinician.name, 220, 420, 40),
        surgeries_insertText(today.format("DD/MM/yyyy"), 500, 437),
        surgeries_blankPage(),
    ]}];
}

function surgeries_calConsentMedicalSurgicalTreatment(data) {
    return [{stack: [
        surgeries_fullPageImage(surgeries_calConsentMedicalSurgicalTreatment_page(0)),
        surgeries_insertDateHeader(surgeries_formatDate(data.surgery.date, 1)),
        surgeries_insertText(data.patient.surname, 360, 53),
        surgeries_insertText(data.patient.given_names, 380, 77),
        surgeries_insertText(surgeries_formatDate(data.patient.dob, 1), 340, 99),
        (data.patient.male ? surgeries_tick(335, 130, 8, 8) : surgeries_tick(362, 130, 8, 8)),
        surgeries_insertText(data.clinician.name, 60, 183),
        surgeries_insertText(`${data.patient.salutation} ${data.patient.given_names} ${data.patient.surname}`, 330, 183),
        surgeries_insertText(surgeries_getProcedureDescription(data), 60, 225),
        surgeries_surgeonSign(data.clinician.name, 170, 360, 40),
        surgeries_insertText(new moment().format("DD/MM/yyyy"), 450, 380),
    ]}];
}

function surgeries_calPrivacyConsent() {
    return surgeries_calPrivacyConsent_page(0);
}

function surgeries_wgSurgeryConsent(data) {
    var today = new moment();
    return [{stack: [
        surgeries_fullPageImage(surgeries_wgSurgeryConsent_page(0)),
        surgeries_insertDateHeader(surgeries_formatDate(data.surgery.date, 1)),
        surgeries_insertText(surgeries_formatDate(data.surgery.date, 1), 140, 132),
        surgeries_insertText(data.clinician.name, 150, 148),
        surgeries_insertText("Patient to advise", 250, 164),
        surgeries_insertText("Patient to advise", 175, 210),
        (data.surgery.anaesthetic === "Local" ? surgeries_tick(199, 331, 7, 7) : surgeries_tick(352, 331, 7, 7)),
        surgeries_insertText(`${data.patient.salutation} ${data.patient.given_names} ${data.patient.surname}`, 60, 434),
        surgeries_insertText(surgeries_getProcedureDescription(data), 50, 486),
        surgeries_insertText(data.clinician.name, 290, 535),
        surgeries_surgeonSign(data.clinician.name, 210, 710, 30),
        surgeries_insertText(today.format("DD"), 440, 724),
        surgeries_insertText(today.format("MM"), 464, 724),
        surgeries_insertText(today.format("yyyy"), 488, 724),
        surgeries_blankPage(),
    ]}];
}

async function surgeries_ruralSurgeryConsent(surgery) {
    const consent_pages = await convertPDFToImages("forms/rural_surgery_consent.pdf");
    const patient_dob = new moment(surgery.patient.dob, "YYYY-MM-DD");
    const today = new moment();
    return [
        {stack: [
            surgeries_fullPageImage(consent_pages[0], ""),
            surgeries_insertText(surgery.patient.surname, 370, 60),
            surgeries_insertText(surgery.patient.given_names, 385, 82),
            surgeries_insertText(surgery.clinic.name, 120, 125),
            surgeries_insertText(patient_dob.format("DD"), 355, 125),
            surgeries_insertText(patient_dob.format("MM"), 390, 125),
            surgeries_insertText(patient_dob.format("YYYY"), 425, 125),
            surgeries_insertText(surgery.patient.male ? "Male" : "Female", 510, 125),
            surgeries_insertText(surgery.patient.given_names, 160, 185),
            surgeries_insertText(surgery.patient.surname, 380, 185),
            surgeries_insertText(surgery.clinician.name, 350, 225),
            surgeries_insertText(surgeries_getProcedureDescription(surgery), 250, 283),
            surgeries_insertText(surgeries_getProcedureTitle(surgery), 260, 313),
            surgeries_insertText(`${surgery.surgery.left_eye ? "Left" : "Right"} eye`, 180, 343),

            surgeries_fullPageImage(consent_pages[1]),
            surgeries_surgeonSign(surgery.clinician.name, 250, 430, 30),
            surgeries_insertText(today.format("DD"), 431, 440),
            surgeries_insertText(today.format("MM"), 466, 440),
            surgeries_insertText(today.format("YYYY"), 497, 440),
        ]}
    ];
}
