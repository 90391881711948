import {surgeries_getHeaderLogo, surgeries_surgeonSignWeng, surgeries_surgeonSignDavid} from "./img/utils";
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from "moment";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
GlobalWorkerOptions.workerSrc = `/pdf.worker.mjs`;

class Surgeries_GapFee {
    constructor(procedure, healthFund, cost, gap) {
        this.procedure = procedure;
        this.healthFund = healthFund;
        this.cost = cost;
        this.gap = gap;
    }

    display(allowGap=true) {
        return `${this.healthFund} ${this.procedure}: (cost $${this.cost.toFixed(2)}) gap $${allowGap ? this.gap : 0}`;
    }
}

export const surgeries_pecOrange = '#C45911';

function surgeries_formatProcedures(procedure) {
    const toDisplay = (str) => {
        // Replace all underscores with spaces and capitalise each word.
        let result = str.replace(/_/g, ' ');
        result = result.replace(/\b\w/g, char => char.toUpperCase());
        return result;
    }
    var chosenProcedures = [];
    for (var [procedure_name, checked] of Object.entries(procedure)) checked && chosenProcedures.push(toDisplay(procedure_name));
    return chosenProcedures.join(", ");
}

export function surgeries_insertText(text, x, y, style='form_med') {
    return {
        layout: 'noBorders',
        table: {
            widths: "*",
            body: [[{text: text, style: style}]],
        },
        absolutePosition: {x: x, y: y},
    };
}

export function surgeries_plotGrid() {
    var canvas = [];
    for (var x = 0; x <= 595; x += 10) {
        canvas.push({
            type: 'line',
            color: x % 100 === 0 ? 'red' : 'grey',
            x1: x, y1: 0,
            x2: x, y2: 842,
            lineWidth: 4
        });
    }
    for (var y = 0; y <= 842; y += 10) {
        canvas.push({
            type: 'line',
            color: y % 100 === 0 ? 'red' : 'grey',
            x1: 0, y1: y,
            x2: 595, y2: y,
            lineWidth: 4
        });
    }
    return {
        canvas: canvas,
        absolutePosition: {x: 0, y: 0}
    };
}

export function surgeries_getProcedureTitle(data) {
    var procedures = [];
    // Capitalise and put space before any terms
    const process = (str) => str.charAt(0).toUpperCase() + str.slice(1).replace(/([A-Z])/g, ' $1');
    Object.entries(data.procedure).forEach(([procedure, selected]) => selected && procedures.push(process(procedure)));
    return procedures.join(", ");
}

export function surgeries_getProcedureDescription(surgery) {
    var descriptions = surgery.surgery.billing_codes.split('|').map(item => {
        let lastParenIndex = item.lastIndexOf('(');
        if (lastParenIndex !== -1) {
            return item.substring(0, lastParenIndex).trim();
        }
        return item.trim();
    });
    return `${surgery.surgery.left_eye ? "Left" : "Right"} ${descriptions.join(', ')}`;
}

export function surgeries_getBillingCodeNumbers(surgery) {
    var codeNumbers = [];
    for (var item of surgery.surgery.billing_codes.split('|')) {
        // Get contents of brackets at the end of billing code text
        const match = item.match(/\(([^)]*)\)$/);
        match && codeNumbers.push(...match[1].split(',').map(item => item.trim()));
    }
    return codeNumbers;
}

export function surgeries_tick(xCentre, yCentre, width, height) {
    return {
        canvas: [{
            type: 'polyline',
            color: null,
            points: [
                {x: xCentre - width / 4, y: yCentre},
                {x: xCentre, y: yCentre + height / 4},
                {x: xCentre + width / 2, y: yCentre - height / 2},
            ]
        }],
        absolutePosition: {x: 0, y: 0}
    };
}

export function surgeries_fullPageImage(imageString, pageBreak='before') {
    return {
        image: imageString,
        absolutePosition: {x: 0, y: 0},
        pageBreak: pageBreak,
        fit: [595, 842],
    };
}

export function surgeries_blankPage() {
    return  {
        text: '',
        pageBreak: 'after'
    };
}

export function surgeries_surgeonSign(surgeon, x, y, height) {
    const insertImage = (ratio, imageString) => {
        return {
            image: imageString,
            absolutePosition: {x: x, y: y},
            height: height,
            width: height * ratio,
        }
    }
    if (surgeon.includes("Weng Chan")) {
        return insertImage(45 / 64, surgeries_surgeonSignWeng());
    }
    if (surgeon.includes("David Sia")) {
        return insertImage(100 / 53, surgeries_surgeonSignDavid());
    }
}

export function surgeries_circle(xCentre, yCentre, radius) {
    return {
        canvas: [{
            type: 'ellipse',
            color: null,
            x: xCentre,
            y: yCentre,
            r1: radius,
            r2: radius,
        }],
        absolutePosition: {x: 0, y: 0}
    };
}

export function surgeries_getApplicableGaps(surgery) {
    const surgeries_gapFees = [
        new Surgeries_GapFee("Cataract", "HCF", 1241.40, 150),
        new Surgeries_GapFee("Cataract", "Bupa", 1234.10, 150),
        new Surgeries_GapFee("Cataract", "AHSA", 1055.50, 250),
        new Surgeries_GapFee("Cataract", "Medibank Private", 1110.55, 200),
        new Surgeries_GapFee("Cataract", "NIB", 1140.95, 0),
        new Surgeries_GapFee("Cataract", "DVA", 1140.95, 0),

        new Surgeries_GapFee("Conjunctiva", "HCF", 0, 500),
        new Surgeries_GapFee("Conjunctiva", "Bupa", 0, 500),
        new Surgeries_GapFee("Conjunctiva", "AHSA", 0, 500),
        new Surgeries_GapFee("Conjunctiva", "Medibank Private", 0, 500),
        new Surgeries_GapFee("Conjunctiva", "NIB", 0, 500),
        new Surgeries_GapFee("Conjunctiva", "DVA", 0, 500),

        new Surgeries_GapFee("Epiretinal Membrane", "HCF", 2034.50, 500),
        new Surgeries_GapFee("Epiretinal Membrane", "Bupa", 2105.45, 500),
        new Surgeries_GapFee("Epiretinal Membrane", "AHSA", 1836.80, 500),
        new Surgeries_GapFee("Epiretinal Membrane", "Medibank Private", 1829.45, 500),
        new Surgeries_GapFee("Epiretinal Membrane", "NIB", 2007.65, 0),
        new Surgeries_GapFee("Epiretinal Membrane", "DVA", 2007.65, 0),

        new Surgeries_GapFee("Macular Hole", "HCF", 2034.50, 500),
        new Surgeries_GapFee("Macular Hole", "Bupa", 2105.45, 500),
        new Surgeries_GapFee("Macular Hole", "AHSA", 1836.80, 500),
        new Surgeries_GapFee("Macular Hole", "Medibank Private", 1829.45, 500),
        new Surgeries_GapFee("Macular Hole", "NIB", 2007.65, 0),
        new Surgeries_GapFee("Macular Hole", "DVA", 2007.65, 0),

        new Surgeries_GapFee("Retinal Detachment", "HCF", 2034.50, 500),
        new Surgeries_GapFee("Retinal Detachment", "Bupa", 2104.45, 500),
        new Surgeries_GapFee("Retinal Detachment", "AHSA", 1836.80, 500),
        new Surgeries_GapFee("Retinal Detachment", "Medibank Private", 1829.45, 500),
        new Surgeries_GapFee("Retinal Detachment", "NIB", 2007.65, 0),
        new Surgeries_GapFee("Retinal Detachment", "DVA", 2007.65, 0),

        new Surgeries_GapFee("Vitrectomy", "HCF", 2034.50, 500),
        new Surgeries_GapFee("Vitrectomy", "Bupa", 2105.45, 500),
        new Surgeries_GapFee("Vitrectomy", "AHSA", 1836.80, 500),
        new Surgeries_GapFee("Vitrectomy", "Medibank Private", 1829.45, 500),
        new Surgeries_GapFee("Vitrectomy", "NIB", 2007.65, 0),
        new Surgeries_GapFee("Vitrectomy", "DVA", 2007.65, 0),
    ]; 
    var predicate = (gap) => surgeries_formatProcedures(surgery.procedure).includes(gap.procedure) && gap.healthFund === surgery.patient.health_fund;
    return surgeries_gapFees.filter(predicate)
}

export function surgeries_getHeader(pageBreak) {
    const mm = (in_mm) => in_mm * 2.835; // Convert to Pts
    return [
        pageBreak ? {text: '', pageBreak: 'before'} : {},
        {
            image: surgeries_getHeaderLogo(),
            width: 250,
            absolutePosition: {x: mm(13), y: mm(13)},
        },
        {
            text: `Address: 1/79 Pennington Tce
                North Adelaide, SA 5006
                Phone: (08) 8267 1700
                Fax: (08) 8239 1550
                Email: info@penningtoneyeclinic.com`,
            alignment: 'right',
        },
    ];
}

export function surgeries_getTitle(text) {
    return {
        text: text,
        style: 'h1',
        margin: [0, 10, 0, 10],
        color: surgeries_pecOrange,
    };
}

// Pass date = undefined to get current moment.
export function surgeries_formatDate(date, format=1) {
    const date_moment = moment.isMoment(date) ? date : new moment(date);
    if (typeof(format) === "string") return date_moment.format(format);
    switch (format) {
        case 2: return date_moment.format("DD MMM YYYY");
        case 3: return date_moment.format("dddd, D MMMM YYYY");
        case 4: return date_moment.format("YYYY-MM-DD_HH-mm-ss");
        default: return date_moment.format("DD/MM/YYYY");
    }
}

export function surgeries_formatTime(time) {
    var [h, m] = time.split(":");
    return (h % 12 ? h % 12 : 12) + ":" + m + " " + (h >= 12 ? "PM" : "AM");
}

export const convertPDFToImages = async (file) => {
    const pdf = await getDocument({ url: file }).promise; // Pass the URL to getDocument
    const numPages = pdf.numPages;
    const imagesArray = [];

    for (let i = 1; i <= numPages; i++) {
        const page = await pdf.getPage(i);
        const viewport = page.getViewport({ scale: 2 }); // Adjust scale for better resolution
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const renderContext = {
            canvasContext: context,
            viewport: viewport,
        };

        await page.render(renderContext).promise;

        const imageData = canvas.toDataURL('image/png');
        imagesArray.push(imageData);
    }

    return imagesArray;
};
