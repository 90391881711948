import {
    surgeries_tick,
    surgeries_fullPageImage,
    surgeries_insertText,
    surgeries_circle,
    surgeries_surgeonSign,
    surgeries_formatDate,
    surgeries_blankPage
} from "../utils";
import {
    surgeries_adsCalDropSheetWeng_page,
    surgeries_adsCalDropSheetDavid_img,
    surgeries_wgDropSheet_page
} from "../img/dropSheet";

export function surgeries_dropSheet(doc, surgery) {
    switch (surgery.clinic.name) {
        case "Adelaide Day Surgery":
        case "Calvary North Adelaide":
            doc.content.push([
                ...surgeries_adsCalDropSheet(surgery)
            ]);
            return;
        case "Windsor Gardens Day Surgery":
            doc.content.push([
                ...surgeries_wgDropSheet(surgery),
            ]);
            return;
        default: return;
    }
}

function surgeries_adsCalDropSheet(surgery) {
    if (surgery.clinician.name.includes("Weng Chan")) {
        const tickboxes = {
            "Left": [
                surgeries_tick(205, 215, 15, 15),
                surgeries_tick(205, 245, 15, 15),
                surgeries_tick(206, 270, 15, 15),
            ],
            "Right": [
                surgeries_tick(253, 215, 15, 15),
                surgeries_tick(252, 245, 15, 15),
                surgeries_tick(254, 270, 15, 15),
            ],
        };
        return [
            surgeries_fullPageImage(surgeries_adsCalDropSheetWeng_page(0)),
            surgeries_insertText(`${surgery.patient.given_names} ${surgery.patient.surname}`, 125, 131),
            surgeries_insertText(surgeries_formatDate(surgery.surgery.date, 1), 340, 131),
            ...tickboxes[surgery.surgery.left_eye ? "Left" : "Right"],
            surgeries_blankPage(),
        ];
    }
    if (surgery.clinician.name.includes("David Sia")) {
        var elements = [];
        if (surgery.procedure.cataract || surgery.procedure.retinal_detachment || surgery.procedure.epiretinal_membrane ||
            surgery.procedure.macular_hole || surgery.procedure.vitrectomy) {
            var topCircles = {
                "Left": [
                    surgeries_circle(168, 199, 14),
                    surgeries_circle(168, 232, 14),
                    surgeries_circle(168, 264, 14),
                    surgeries_circle(168, 295, 14),
                    surgeries_circle(168, 326, 14),
                ],
                "Right": [
                    surgeries_circle(219, 199, 14),
                    surgeries_circle(219, 232, 14),
                    surgeries_circle(219, 264, 14),
                    surgeries_circle(219, 295, 14),
                    surgeries_circle(219, 326, 14),
                ],
            };
            elements.push(
                {
                    image: surgeries_adsCalDropSheetDavid_img(0),
                    absolutePosition: {x: 30, y: 20},
                    width: 535,
                },
                surgeries_insertText(`${surgery.patient.given_names} ${surgery.patient.surname}`, 100, 107),
                surgeries_insertText(surgeries_formatDate(surgery.surgery.date, 1), 410, 107),
                ...topCircles[surgery.surgery.left_eye ? "Left" : "Right"],
            );
        }
        if (surgery.procedure.conjunctiva || surgery.procedure.pterygium) {
            var bottomCircles = {
                "Left": [
                    surgeries_circle(172, 619, 14),
                    surgeries_circle(172, 654, 14),
                ],
                "Right": [
                    surgeries_circle(218, 619, 14),
                    surgeries_circle(218, 654, 14),
                ],
            }
            elements.push([
                {
                    image: surgeries_adsCalDropSheetDavid_img(1),
                    absolutePosition: {x: 30, y: 461},
                    width: 535,
                },
                surgeries_insertText(`${surgery.patient.given_names} ${surgery.patient.surname}`, 100, 531),
                surgeries_insertText(surgeries_formatDate(surgery.surgery.date, 1), 410, 531),
                ...bottomCircles[surgery.surgery.left_eye ? "Left" : "Right"],
            ]);
        }

        // Add a page break before the elements if one or more drop sheets has been inserted.
        if (elements.length > 0) {
            elements.unshift(surgeries_blankPage());
            elements.push(surgeries_blankPage());
        }
        return elements;
    }
}

function surgeries_wgDropSheet(surgery) {
    const circles = {
        "Left": [
            surgeries_circle(225, 370, 5),
            surgeries_circle(225, 485, 5),
        ],
        "Right": [
            surgeries_circle(203, 370, 5),
            surgeries_circle(203, 485, 5),
        ],
    };
    return [{stack: [
        surgeries_fullPageImage(surgeries_wgDropSheet_page(0)),
        surgeries_insertText(surgery.patient.surname, 355, 51),
        surgeries_insertText(surgery.patient.given_names, 355, 66),
        surgeries_insertText(surgeries_formatDate(surgery.patient.dob, 1), 355, 112),
        (surgery.patient.male ? surgeries_tick(509, 121, 7, 7) : surgeries_tick(535, 121, 7, 7)),
        surgeries_insertText(surgeries_formatDate(surgery.surgery.date, 1), 54, 240, "form_small"),
        surgeries_surgeonSign(surgery.clinician.name, 160, 220, 40),
        surgeries_insertText(surgery.clinician.name, 428, 239, "form_small"),
        surgeries_tick(165, 373, 14, 14),
        surgeries_insertText("1", 260, 363),
        surgeries_insertText("3", 310, 363),
        surgeries_insertText("20", 360, 363),
        surgeries_tick(165, 488, 14, 14),
        surgeries_insertText("1", 260, 478),
        surgeries_insertText("3", 310, 478),
        surgeries_insertText("20", 360, 478),
        ...circles[surgery.surgery.left_eye ? "Left" : "Right"],
        surgeries_fullPageImage(surgeries_wgDropSheet_page(1)),
        surgeries_insertText(surgery.patient.surname, 90, 49),
        surgeries_insertText(surgery.patient.given_names, 90, 64),
        surgeries_insertText(surgeries_formatDate(surgery.patient.dob, 1), 90, 110),
        (surgery.patient.male ? surgeries_tick(244, 119, 7, 7) : surgeries_tick(270, 119, 7, 7)),
    ]}];
}
